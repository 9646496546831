import { computed, Directive, input } from '@angular/core';

export type FontWeight = 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold';

const weights: Record<string, string> = {
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold tracking-tight',
  bold: 'font-bold tracking-tight',
  extrabold: 'font-extrabold tracking-tight',
};

const defaultFontWeight: FontWeight = 'normal';

@Directive({
  selector: '[wmaFontWeight]',
  host: {
    '[class]': 'hostClasses()',
  },
  standalone: false,
})
export class FontWeightDirective {
  weight = input<FontWeight | undefined>(undefined, {
    alias: 'wmaFontWeight',
  });

  hostClasses = computed(() => {
    return `${weights[this.weight() || defaultFontWeight]}`;
  });
}
