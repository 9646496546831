import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavLink } from '@welma-education/common';

export type VerticalNavListColor = 'primary' | 'secondary';
const defaultColor: VerticalNavListColor = 'primary';

const colors: Record<VerticalNavListColor, Record<'active' | 'inactive', string>> = {
  primary: {
    active: 'border-primary-600 bg-primary-50 text-primary-600',
    inactive: 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
  },
  secondary: {
    active: 'border-secondary-600 bg-secondary-50 text-secondary-600',
    inactive: 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
  },
};

@Component({
  selector: 'wma-vertical-nav-list',
  templateUrl: 'vertical-nav-list.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'block',
  },
  imports: [RouterLinkActive, RouterLink],
})
export class VerticalNavListComponent {
  links = input<NavLink[]>([]);
  color = input<VerticalNavListColor>();

  klass = computed(() => {
    return colors[this.color() || defaultColor];
  });
}
