import { computed, Directive, input } from '@angular/core';

export type TypeScale = 'headlineMedium' | 'headlineLarge';

const typeScales: Record<TypeScale, string> = {
  headlineMedium: 'text-lg font-medium text-gray-900',
  headlineLarge: 'text-2xl font-semibold text-gray-900',
};

@Directive({
  selector: '[wmaTypeScale]',
  host: {
    '[class]': 'hostClasses()',
  },
  standalone: false,
})
export class TypeScaleDirective {
  typeScale = input.required<TypeScale>({
    alias: 'wmaTypeScale',
  });

  hostClasses = computed(() => {
    return `${typeScales[this.typeScale()]}`;
  });
}
