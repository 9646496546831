import { Component, input } from '@angular/core';
import { CdkStep, CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'wma-stepper',
  templateUrl: './stepper.component.html',
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  imports: [CdkStepperModule, ButtonModule, IconModule, NgTemplateOutlet],
})
export class StepperComponent extends CdkStepper {
  free = input(false);
  selectStep(step: CdkStep) {
    if (this.free()) {
      step.select();
    }
  }
}
