import { ChangeDetectionStrategy, Component, input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';

import { IconModule } from '../icon';

@Component({
  selector: 'wma-search-box',
  templateUrl: './search-box.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchBoxComponent,
      multi: true,
    },
  ],
  imports: [ReactiveFormsModule, IconModule],
})
export class SearchBoxComponent implements ControlValueAccessor, OnDestroy {
  static nextId = 0;
  id = `wma-search-box-${SearchBoxComponent.nextId++}`;

  label = input('');
  placeholder = input('');
  debounceTime = input(400);

  search = new FormControl('', { nonNullable: true });
  private destroyed$ = new Subject<void>();
  onTouched = () => {
    // noop
  };

  writeValue(value: string): void {
    this.search.setValue(value);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: () => void): void {
    this.search.valueChanges
      .pipe(
        debounceTime(this.debounceTime()),
        distinctUntilChanged(),
        map((value) => value.toLowerCase()),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(fn);
  }

  setDisabledState(disabled: boolean): void {
    disabled ? this.search.disable() : this.search.enable();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
