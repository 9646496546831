import { Component, computed, effect, inject, input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from '../button';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'wma-paginator',
  templateUrl: './paginator.component.html',
  host: {
    class: 'block',
  },
  imports: [ButtonModule, ReactiveFormsModule],
})
export class PaginatorComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);
  first = input(false);
  last = input(false);
  number = input(0);
  size = input(0);
  totalElements = input(0);
  numberOfElements = input(0);
  totalPages = input.required<number>();

  control = new FormControl();
  pages = computed(() => {
    return [...Array(this.totalPages()).keys()];
  });

  constructor() {
    effect(() => {
      this.control.setValue(this.number(), { emitEvent: false });
    });
  }

  goToPrevious() {
    this.goToPage(this.number() + -1);
  }

  goToNext() {
    this.goToPage(this.number() + 1);
  }

  goToPage(page: number) {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { page },
      queryParamsHandling: 'merge',
    });
  }

  onSelectChange(page: string) {
    this.goToPage(Number(page));
  }
}
